<template>
  <v-card class="rounded-lg shadow-regular">
    <v-card-title class="section-title py-5 px-7"> Cards </v-card-title>
    <v-container class="py-5 px-7">
      <v-row>
        <v-col cols="4">
          <v-card class="rounded-lg shadow-small">
            <v-card-title class="section-title d-flex justify-space-between">
              <p class="mb-0">Small Shadow</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Small Shadow')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>This is a small-shadow card.</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="rounded-lg shadow-regular">
            <v-card-title class="section-title d-flex justify-space-between">
              <p class="mb-0">Regular Shadow</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Regular Shadow')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>This is a regular-shadow card.</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="rounded-lg shadow-large">
            <v-card-title class="section-title d-flex justify-space-between">
              <p class="mb-0">Large Shadow</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Large Shadow')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>This is a large-shadow card.</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card class="rounded-lg border-grey-small" elevation="0">
            <v-card-title class="section-title d-flex justify-space-between">
              <p class="mb-0">Border Grey</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Border Grey')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>This is a border-grey card.</p>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="4">
          <v-card class="rounded-lg" color="ashLight" elevation="0">
            <v-card-title class="section-title d-flex justify-space-between">
              <p class="mb-0">Grey Background</p>
              <v-btn
                icon
                color="caribbeanDark"
                @click="copyToClipboard('Grey Background')"
              >
                <v-icon>mdi-code-tags</v-icon>
              </v-btn>
            </v-card-title>
            <v-card-text>
              <p>This is a grey card.</p>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'CardsWiki',

  methods: {
    triggerToast(name) {
      const toastParams = {
        position: { top: true, right: true },
        timeout: 3000,
        messages: { success: `${name} copied to clipboard` },
        type: 'success',
      };

      this.$store.dispatch(
        'toastNotification/setupToastNotification',
        toastParams
      );
      this.$store.dispatch('toastNotification/showToastNotification', true);
    },

    copyToClipboard(name) {
      let component = '';
      switch (name) {
        case 'Regular Shadow':
          component = `<v-card class="rounded-lg shadow-regular"></v-card>`;
          break;

        case 'Small Shadow':
          component = `<v-card class="rounded-lg shadow-small"></v-card>`;
          break;

        case 'Large Shadow':
          component = `<v-card class="rounded-lg shadow-large"></v-card>`;
          break;

        case 'Border Grey':
          component = `<v-card class="rounded-lg border-grey-small" elevation="0"></v-card>`;
          break;

        case 'Grey Background':
          component = `<v-card class="rounded-lg" color="ashLight" elevation="0"></v-card>`;
      }

      navigator.clipboard.writeText(component);
      this.triggerToast(name);
    },
  },
};
</script>

<style></style>
